import React, { useState, useEffect } from 'react';
import './App.css'; // Import the CSS file for styling

const App = () => {
  const [assetId, setAssetId] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Set assetId from the 'assetId' query parameter on component mount
    const params = new URLSearchParams(window.location.search);
    const initialAssetId = params.get('assetId');
    setAssetId(initialAssetId);
  }, []);

  const handleButtonClick = async () => {
    try {
      setLoading(true);
      // Have to encode the assetId twice to handle special characters as for the CloudFlare rule
      const encodedAssetId = encodeURIComponent(encodeURIComponent(assetId));
      const apiUrl = `${window.config.BACKEND_URL}${encodedAssetId}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      setResponse(data.value);
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponse('Error fetching data. Please check the console for details.');
    } finally {
      setLoading(false);
    }
  };

  const renderResponse = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (!response) return null;

    // Split the value by the "|" character
    const parts = response.split('|').map((part) => part.trim());

    return (
      <div>
        <h2>Response:</h2>
        <ul>
          {parts.map((part, index) => (
            <li key={index}>{part}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="app-container">
      <h1>Asset App</h1>

      <div className="input-container">
        <label htmlFor="assetId">Enter Asset ID: </label>
        <input
          type="text"
          id="assetId"
          value={assetId}
          onChange={(e) => setAssetId(e.target.value)}
        />
      </div>

      <button onClick={handleButtonClick}>Search</button>

      {renderResponse()}
    </div>
  );
};

export default App;